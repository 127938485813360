@import "gridjs/dist/theme/mermaid.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
[x-cloak] {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
  transition: color 0.3s;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.btn {
  @apply text-white bg-green-darker text-14 rounded font-semibold cursor-pointer;
  padding: 15px 35px;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1), transform 0.45s cubic-bezier(0.25, 1, 0.33, 1), border-color 0.45s cubic-bezier(0.25, 1, 0.33, 1), color 0.45s cubic-bezier(0.25, 1, 0.33, 1), background-color 0.45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow 0.45s cubic-bezier(0.25, 1, 0.33, 1);
}
.btn.small {
  font-size: 12px;
  padding: 4px 20px;
}
.btn:hover {
  @apply bg-olive;
  box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16) !important;
  transform: translateY(-3px);
}

input[type=text],
input[type=password],
input[type=number],
input[type=email],
select {
  @apply ring-green-darker outline-green-darker;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 2px solid transparent;
  margin-top: 8px;
}

select {
  margin-top: 0;
}

label {
  font-size: 18px;
}

.prose h1, .prose h2, .prose h3 {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}
.prose h1:first-child, .prose h2:first-child, .prose h3:first-child {
  margin-top: 0px;
}
.prose ol {
  margin-top: 24px;
}
.prose ol li {
  list-style: decimal;
  margin-bottom: 24px;
}

.spot-pin:hover + .callout {
  opacity: 1;
}